<template>
  <v-alert
    outlined
    type="error"
    prominent
    border="left"
  >
    <h3 class="headline">{{'data_load.failure.content'| t}}</h3>

    <v-spacer></v-spacer>

    <v-btn color="error" small outlined @click="$router.go(-1)">
      <v-icon>fa fa-arrow-left</v-icon>
      <span>{{'action.back'| t}}</span>
    </v-btn>
  </v-alert>
</template>

<script>
export default {
  data: () => ({
    
  }),
  created() {},
}
</script>

<style lang="sass" type="text/sass" scoped></style>